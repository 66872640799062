import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['searchItem']

    connect() {
      for (const parent of document.querySelectorAll('[data-multi-level-selector-parent-name]')) {
        this._updateParentStatus(parent);
      }

      for (const parent of document.querySelectorAll('[data-multi-level-selector-target-id]')) {
        document.querySelector(`#${parent.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !parent.checked);
      }
    }

    search = (event) => {
      const search_text = event.target.value;

      for (const search_target of this.searchItemTargets) {
        if (search_text.length > 0) {
          search_target.classList.toggle('hidden', !(search_target.dataset.multiLevelSelectorSearch.indexOf(search_text) >= 0));
        } else {
          search_target.classList.toggle('hidden', false);
        }
      }
    }

    toggleLevel = (event) => {
      document.querySelector(`#${event.target.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !event.target.checked);
    }

    toggleChildren = (event) => {
      this._toggleChildren(event.target);
    }

    _toggleChildren(triggeringParent) {
      const selector = `[data-multi-level-selector-parent="${triggeringParent.dataset.multiLevelSelectorParentName}"]`;
      for (const child of document.querySelectorAll(selector)) {
        child.checked = triggeringParent.checked;

        if (child.dataset.multiLevelSelectorTargetId) {
          document.querySelector(`#${child.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !child.checked);
        }

        if (child.dataset.multiLevelSelectorParentName) {
          this._toggleChildren(child);
        }
      }
    }

    toggleParent = (event) => {
      const parentName = event.target.dataset.multiLevelSelectorParent;
      const parent = document.querySelector(`[data-multi-level-selector-parent-name="${parentName}"]`);
      this._updateParentStatus(parent);
    }

    _updateParentStatus(parent) {
      let all_checked = true;
      let all_unchecked = true;
      let any_indeterminate = false;
      const selector = `[data-multi-level-selector-parent="${parent.dataset.multiLevelSelectorParentName}"]`;
      for (const child of document.querySelectorAll(selector)) {
        if (child.checked) {
          all_unchecked = false;
        } else {
          all_checked = false;
        }

        if (child.indeterminate) {
          any_indeterminate = true;
        }
      }

      parent.indeterminate = (!all_checked && !all_unchecked) || any_indeterminate;
      if (all_checked) {
        parent.checked = true;
      }
      if (all_unchecked) {
        parent.checked = false;
      }

      if (parent.dataset.multiLevelSelectorTargetId) {
        document.querySelector(`#${parent.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !parent.checked);
      }

      if (parent.dataset.multiLevelSelectorParent) {
        const grandParent = document.querySelector(`[data-multi-level-selector-parent-name="${parent.dataset.multiLevelSelectorParent}"]`);
        this._updateParentStatus(grandParent);
      }
    }

  toggleNode = (event) => {
    const { nodeId } = event.target.dataset;
    const { checked } = event.target;

    this._toggleChildrenOf(nodeId, checked);
    this._toggleReplicasOf(nodeId, checked, event.target);
  }

  _toggleChildrenOf(parentId, checked) {
    document.querySelectorAll(`[data-parent-id='${parentId}']`)
      .forEach((el) => {
        const wrapper = el.closest('div.multi-selector-wrapper');
        if (wrapper) {
          wrapper.classList.toggle('hidden', !checked);
        }
        el.checked = checked;
      });
  }

  _toggleReplicasOf(nodeId, checked, triggeringNode) {
    document.querySelectorAll(`[data-node-id='${nodeId}']`)
      .forEach((el) => {
        if (el !== triggeringNode) {
          const wrapper = el.closest('div.multi-selector-wrapper');
          if (wrapper) {
            wrapper.classList.toggle('hidden', !checked);
          }
          el.checked = checked;
        }
      });
  }
}
